// Mapping that pairs productCodes of CFU courses with productCodes of courses without CFUs
// Note: This is a manual mapping, and will need to be updated if the CFU course list changes

// Key: CFU course productCode
// Value: Course productCode without CFUs
const coursesMap: { [key: string]: string } = {
  'OZ23002400': 'OZ23010900',
  'OZ22012000': 'OZ23003200',
  'OZ23007200': 'OZ23009500',
  'OZ23007800': 'OZ23008200',
  'OZ23009700': 'OZ23008000',
  'LZ23009600': 'LZ24004300',
  'LZ24002700': 'LZ24002600'
};

// Add cookie to the browser with the choosen
export const setCFUCookie = (value: 'with' | 'without'): void => {
  if (value === 'with') {
    document.cookie = 'cfu=with; path=/';
  } else {
    document.cookie = 'cfu=without; path=/';
  }
}

export const getCFUCookie = (): 'with' | 'without' | null => {
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i].trim();
    if (c.indexOf('cfu=') === 0) {
      return c.substring('cfu='.length, c.length) as 'with' | 'without';
    }
  }
  return null;
}

// Returns the productCode of the course without CFUs given the productCode of the CFU course
export const getCourseWithoutCFUs = (productCode: string): string | null => {
  // return null if the productCode is not in the map
  if (!Object.keys(coursesMap).includes(productCode)) {
    return null;
  }
  return coursesMap[productCode];
};

// Returns the productCode of the course without CFUs given the productCode of the CFU course
export const getCourseWithCFUs = (productCode: string): string | null => {
  // return null if the productCode is not in the map
  if (!Object.values(coursesMap).includes(productCode)) {
    return null;
  }
  return Object.keys(coursesMap).find(key => coursesMap[key] === productCode);
};

// Returns true if the course is in the map
export const courseHasCFUCourse = (productCode: string): boolean => {
  return Object.keys(coursesMap).includes(productCode) || Object.values(coursesMap).includes(productCode);
};

// Returns true if is CFU course
export const isCFUCourse = (productCode: string): boolean => {
  return Object.keys(coursesMap).includes(productCode);
};

